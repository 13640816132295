<template>
  <b-row class="align-items-baseline">
    <b-col
      md="2"
      cols="3"
      class="pr-0 text-right"
    >
      <b-button
        :id="tourKeyword"
        variant="flat-secondary"
        class="p-1"
        @click="callback ? $emit('back') : routeName ? $router.replace({ name: routeName, params, queries }) : $router.go(-1)"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="15"
        />
        Back
      </b-button>
    </b-col>

    <b-col
      md="8"
      cols="9"
      class="pl-0"
    >
      <h4 class="pl-1 mb-0 border-left border-2">
        {{ title }}
      </h4>
    </b-col>
  </b-row>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    routeName: {
      type: String,
      default: () => null,
    },
    params: {
      type: Object,
      default: () => {},
    },
    queries: {
      type: Object,
      default: () => {},
    },
    callback: {
      type: Boolean,
      default: () => false,
    },
    tourKeyword: {
      type: String,
      default: () => null,
    },
  },
}
</script>
