<template>
  <div>
    <b-tabs
      v-model="activeTab"
      pills
      content-class="d-flex justify-content-end"
    >
      <b-tab
        v-for="(item,index) in ranges"
        :key="index"
        lazy
        @click="$emit('change',item.dateRange)"
      >
        <template #title>
          <span
            v-b-tooltip.top="item.description"
            class="font-weight-bold"
          >
            {{ item.title }}
          </span>
        </template>
      </b-tab>

    </b-tabs>
  </div>
</template>
<script>
import { BTab, BTabs } from 'bootstrap-vue'

const moment = require('moment')

export default {
  components: {
    BTab,
    BTabs,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    tillDayBefore: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      activeTab: 0,
      ranges: [
        {
          title: 'MTD',
          dateRange: this.tillDayBefore
            ? { startTime: moment(this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start).startOf('month').format('YYYY-MM-DD HH:mm:ss'), endTime: this.maxDate() }
            : { startTime: moment(this.TODAY({ localTime: false })).startOf('month').format('YYYY-MM-DD HH:mm:ss'), endTime: this.maxDate() },
          description: 'Month to Date',
        },
        {
          title: 'P3M',
          dateRange: this.tillDayBefore
            ? { startTime: moment(this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start).startOf('month').subtract(2, 'month').format('YYYY-MM-DD HH:mm:ss'), endTime: this.maxDate() }
            : { startTime: moment(this.TODAY({ localTime: false })).startOf('month').subtract(2, 'month').format('YYYY-MM-DD HH:mm:ss'), endTime: this.maxDate() },
          description: 'Past 3 months',
        },
        {
          title: 'P6M',
          dateRange: this.tillDayBefore
            ? { startTime: moment(this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start).startOf('month').subtract(5, 'month').format('YYYY-MM-DD HH:mm:ss'), endTime: this.maxDate() }
            : { startTime: moment(this.TODAY({ localTime: false })).startOf('month').subtract(5, 'month').format('YYYY-MM-DD HH:mm:ss'), endTime: this.maxDate() },
          description: 'Past 6 months',
        },
        {
          title: 'YTD',
          dateRange: this.tillDayBefore
            ? { startTime: moment(this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start).startOf('year').format('YYYY-MM-DD HH:mm:ss'), endTime: this.maxDate() }
            : { startTime: moment(this.TODAY({ localTime: false })).startOf('year').format('YYYY-MM-DD HH:mm:ss'), endTime: this.maxDate() },
          description: 'Year to Date',
        },
        {
          title: 'Custom',
          dateRange: { startTime: null, endTime: null },
          description: 'Custom Date',
        },
      ],
    }
  },
  methods: {
    maxDate() {
      return this.tillDayBefore
        ? this.SUBTRACT_DAYS(this.TODAY_COMPANY_TIME(), 1).start
        : this.TODAY_COMPANY_TIME().start
    },
  },

}
</script>

<style>
@media screen and (max-width: 768px) {
  .nav-pills .nav-link {
    padding: 0.5rem 1rem;
    margin-bottom: 8px;
  }
}
</style>
